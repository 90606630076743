import { domReady } from './dom-ready';
import { monsPathsToForcePageDirection } from './mons-paths-forced-direction';

type DirChangeCallback = (dir: string) => void;

let htmlEl = null;
let observing = false;
let waitingForDom = false;
const dirChangeCallbacks: Set<DirChangeCallback> = new Set();

const dirChangeObserver = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'dir') {
      notifyDirChangeListeners();
    }
  });
});

/**
 * Register a callback that will be called whenever html's `dir` attribute
 * changes. Called with the new value of `dir`.
 * @param cb
 */
export function onHtmlDirChange(cb: DirChangeCallback) {
  startObserver();
  dirChangeCallbacks.add(cb);
}

export function offHtmlDirChange(cb: DirChangeCallback) {
  dirChangeCallbacks.delete(cb);
}

export function getHtmlDir() {
  if (isPageDirectionForced()) {
    return '';
  }
  return getHtmlEl().getAttribute('dir');
}

export function isPageDirectionRightToLeft() {
  const dir = getHtmlDir();
  return dir && dir.toLowerCase() === 'rtl';
}

function startObserver() {
  if (!observing && !waitingForDom) {
    waitingForDom = true;
    domReady(() => {
      waitingForDom = false;
      observing = true;
      dirChangeObserver.observe(getHtmlEl(), {
        attributes: true,
      });
    });
  }
}

function notifyDirChangeListeners() {
  const htmlDir = getHtmlDir();
  dirChangeCallbacks.forEach(cb => {
    cb(htmlDir);
  });
}

function getHtmlEl() {
  if (!htmlEl) {
    htmlEl = document.querySelector('html');
  }
  return htmlEl;
}

/**
 * Checks if the window href is a mons app path that should be forced to LTR direction.
 * SIM: https://sim.amazon.com/issues/D14154064
 * @returns true if the window.location.href should be forced to LTR direction.
 */
function isPageDirectionForced() {
  const url = window.location.href;
  return monsPathsToForcePageDirection.some(path => url.indexOf(path) > -1);
}
